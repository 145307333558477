module.exports = [{
      plugin: require('/builds/plutux/info-web-content/core/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/builds/plutux/info-web-content/core/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-116039056-1","head":true},
    },{
      plugin: require('/builds/plutux/info-web-content/core/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048},
    },{
      plugin: require('/builds/plutux/info-web-content/core/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"enableIdentityWidget":false,"modulePath":"/builds/plutux/info-web-content/core/src/cms/cms.js"},
    },{
      plugin: require('/builds/plutux/info-web-content/core/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"tomato","showSpinner":false},
    },{
      plugin: require('/builds/plutux/info-web-content/core/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
